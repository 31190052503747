export const DEFAULT_SCORE = {
    ActivityLevel: 0,
    Sociability: 0,
    AffectionLevel: 0,
    PetFriendly: 0,
    KidFriendly: 0,
    Intelligence: 0,
    Playfulness: 0,
    Independence: 0,
    Vocality: 0,
    Grooming: 0,
};