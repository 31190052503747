import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import {
  Radar,
  RadarChart,
  PolarGrid,
  PolarAngleAxis,
  PolarRadiusAxis
} from "recharts";
import { CatBreed, metrics, Score } from '../types';

interface ResultProps {
  score: Score;
  breed: CatBreed;
  onRestart: () => void;
}

const Result: React.FC<ResultProps> = ({ score, breed, onRestart }) => {
  const data = metrics.map(metric => ({
    breed: breed[metric],
    you: score[metric],
    metric,
    fullMark: 100,
  }));

  return (
    <Box mt={3} textAlign="center">
      <Typography variant="h5" gutterBottom>診断結果</Typography>
      <Typography variant="body1" mb={3}>あなたの猫の品種は「{breed.Breed}」です！</Typography>
      {/** 評価項目をレーダーチャート形式で表示する */}
      <RadarChart
        cx={300}
        cy={250}
        outerRadius={150}
        width={500}
        height={500}
        data={data}
      >
        <PolarGrid />
        <PolarAngleAxis dataKey="metric" />
        <PolarRadiusAxis />
        <Radar
          name="breed"
          dataKey="breed"
          stroke="#8884d8"
          fill="#8884d8"
          fillOpacity={0.6}
        />
        <Radar
          name="you"
          dataKey="you"
          stroke="#bdbd2d"
          fill="#bdbd2d"
          fillOpacity={0.6}
        />
      </RadarChart>
      <Box mt={2}>
        <Button variant="contained" color="secondary" onClick={onRestart}>
          もう一度診断する
        </Button>
      </Box>
    </Box>
  );
};

export default Result;
