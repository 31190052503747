import { Metric, metrics, Question, Score } from "./types";

export const getQuestionMetricCounts = (questions: Question[]) => {
  return questions.reduce((acc, question) => {
    return metrics.reduce((prev, metric) => metric in question ? { ...prev, [metric]: (prev[metric] || 0) + 1 } : prev, acc);
                          
  }, {} as Record<Metric, number>);
}

export const getFinalScore = (score: Score, record: Record<Metric, number>) => {
  return metrics.reduce((prev, metric) => {
    return {
      ...prev,
      [metric]: record[metric] > 0 ? score[metric] / record[metric] : 0
    }
  }, score);
}