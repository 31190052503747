import React, { useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { AnswerType, Score, Question, addScore, answers, formatAnswer } from '../types';
import { getFinalScore, getQuestionMetricCounts } from '../utils';

interface QuizProps {
  score: Score,
  onChangeScore: (score: Score) => void;
  onResult: (score: Score) => void;
}

const questions: Question[] = [
  {
    question: '運動や体を動かすことが好き',
    ActivityLevel: { yes: 100, maybe: 50, no: 0 }, // `as const`を追加
    Playfulness: { yes: 100, maybe: 50, no: 0 },
  },
  {
    question: '休日は1人で過ごす方が多い',
    Sociability: { yes: 0, maybe: 50, no: 100 },
    Independence: { yes: 100, maybe: 50, no: 0 },
  },
  {
    question: '学生時代、テストの成績が良かった',
    Intelligence: { yes: 100, maybe: 50, no: 0 },
  },
  {
    question: '小さい子供は苦手',
    AffectionLevel: { yes: 0, maybe: 50, no: 100 },
    KidFriendly: { yes: 0, maybe: 50, no: 100 },
  },
  {
    question: '家族や友達の中で、自分はよく喋るほうだ',
    Vocality: { yes: 100, maybe: 50, no: 0 },
  },
  {
    question: '動物は基本的になんでも好き',
    AffectionLevel: { yes: 100, maybe: 50, no: 0 },
    PetFriendly: { yes: 100, maybe: 50, no: 0 },
  },
  {
    question: '休日は1人で過ごす方が多い？',
    ActivityLevel: { yes: 0, maybe: 0, no: 0 }, // `as const`を追加
    Sociability: { yes: 0, maybe: 50, no: 100 },
    AffectionLevel: { yes: 0, maybe: 0, no: 0 },
    PetFriendly: { yes: 0, maybe: 0, no: 0 },
    KidFriendly: { yes: 0, maybe: 0, no: 0 },
    Intelligence: { yes: 0, maybe: 0, no: 0 },
    Playfulness: { yes: 0, maybe: 0, no: 0 },
    Independence: { yes: 100, maybe: 50, no: 0 },
    Vocality: { yes: 0, maybe: 0, no: 0 },
    Grooming: { yes: 0, maybe: 0, no: 0 },
  },
  // 他の質問を追加
];

const Quiz: React.FC<QuizProps> = ({ score, onChangeScore, onResult }) => {
  const [step, setStep] = useState(0);

  const handleAnswer = (answer: AnswerType) => {
    const newScore = addScore(score, questions[step], answer);

    onChangeScore(newScore);
    if (step < questions.length - 1) {
      setStep(step + 1);
    } else {
      onResult(getFinalScore(newScore, getQuestionMetricCounts(questions)));
    }
  };

  return (
    <Box mt={3}>
      <Typography variant="h6" gutterBottom>{questions[step].question}</Typography>
      {answers.map(answer => (
        <Button key={answer} onClick={() => handleAnswer(answer)}>{formatAnswer(answer)}</Button>
      ))}
    </Box>
  );
};

export default Quiz;