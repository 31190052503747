import React, { useCallback, useEffect, useState } from 'react';
import { Box, Container, Typography } from '@mui/material';
import Quiz from './components/Quiz';
import Result from './components/Result';
import Papa from 'papaparse';
import { CatBreed, distScore, Score } from './types';
import { DEFAULT_SCORE } from './constants';
import { Header } from './components/Header';
import { Footer } from './components/Footer';



const App: React.FC = () => {
  const [breeds, setBreeds] = useState<CatBreed[]>([]);
  const [score, setScore] = useState<Score>(DEFAULT_SCORE);
  const [selectedBreed, setSelectedBreed] = useState<CatBreed | null>(null);

  useEffect(() => {
    // CSVファイルの読み込み
    Papa.parse('/assets/cat_breeds.csv', {
      download: true,
      header: true,
      complete: (results) => {
        const parsedData = results.data as CatBreed[];
        setBreeds(parsedData.map(breed => ({
          Breed: breed.Breed,
          ActivityLevel: Number(breed.ActivityLevel),
          Sociability: Number(breed.Sociability),
          AffectionLevel: Number(breed.AffectionLevel),
          PetFriendly: Number(breed.PetFriendly),
          KidFriendly: Number(breed.KidFriendly),
          Intelligence: Number(breed.Intelligence),
          Playfulness: Number(breed.Playfulness),
          Independence: Number(breed.Independence),
          Vocality: Number(breed.Vocality),
          Grooming: Number(breed.Grooming),
        })));
      }
    });
  }, []);

  const handleChangeScore = useCallback((newScore: Score) => {
    setScore(newScore);
  }, []);

  const handleResult = useCallback((newScore: Score) => {
    setScore(newScore);
    const [closestBreed,] = breeds.reduce<[CatBreed | null, number]>(([prev, distance], breed) => {
      const newDistance = distScore(newScore, breed);
      return newDistance < distance ? [breed, newDistance] : [prev, distance];
    }, [null, Infinity]);

    if (closestBreed) {
      setSelectedBreed(closestBreed);
    }
  }, [breeds]);

  const handleRestart = () => {
    setScore(DEFAULT_SCORE);
    setSelectedBreed(null);
  };

  return (
    <Box sx={{
      width: '100vw',
      height: '100vh',
      margin: 0,
      padding: 0
    }}>
      <Header />
      <Container maxWidth="sm" style={{ marginTop: '50px', textAlign: 'center' }}>
        <Typography variant="h4" gutterBottom>性格診断</Typography>
        <Typography variant="subtitle1" gutterBottom>あなたを猫で例えると？</Typography>
        {selectedBreed ? (
          <Result score={score} breed={selectedBreed} onRestart={handleRestart} />
        ) : (
          <Quiz score={score} onChangeScore={handleChangeScore} onResult={handleResult} />
        )}
      </Container>
      <Footer />
    </Box>
  );
};

export default App;
