import { AppBar, Box, Typography } from "@mui/material";

export const Footer = () => {
  return (
    <AppBar position="fixed" color="primary" sx={{ top: 'auto', bottom: 0 }}>

      <Box sx={{ textAlign: "center" }}>
        <Typography variant="caption">©2024 Code Delivery</Typography>
      </Box>
    </AppBar>
  );
};
