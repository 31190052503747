import { AppBar, Box, Toolbar, Typography } from "@mui/material";

export const Header = () => {
  return (
    <AppBar
      position="static"
      sx={{
        overflow: "hidden",
        width: "100%",
        height: "80px",
      }}
    >
      <Box
        sx={{
          position: "absolute",
          width: "100%",
          height: "80px",
          background: 'url("/images/cat_header01.png") no-repeat',
          backgroundSize: "auto 80px",
          animation: "slide1 40s linear infinite",
          "@keyframes slide1": {
            "0%": {
              transform: "translate3d(-100px, 0, 0)",
            },
            "100%": {
              transform: "translate3d(calc(100vw + 500px), 0, 0)",
            },
          },
        }}
      />
      <Box
        sx={{
          position: "absolute",
          width: "100%",
          height: "80px",
          background: 'url("/images/cat_header02.png") no-repeat',
          backgroundSize: "auto 80px",
          animation: "slide2 40s linear infinite",
          "@keyframes slide2": {
            "0%": {
              transform: "translate3d(-300px, 0, 0)",
            },
            "100%": {
              transform: "translate3d(calc(100vw + 300px), 0, 0)",
            },
          },
        }}
      />
      <Toolbar
        sx={{
          width: "100%",
          height: "80px",
          display: "flex",
          alignItems: "center",
          flexDirection: "row",
        }}
      >
        <Typography variant="h5" component="div">
          ねこふゅーじょん
        </Typography>
      </Toolbar>
    </AppBar>
  );
};
