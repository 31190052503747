export const metrics = [
    "ActivityLevel",
    "Sociability",
    "AffectionLevel",
    "PetFriendly",
    "KidFriendly",
    "Intelligence",
    "Playfulness",
    "Independence",
    "Vocality",
    "Grooming",
  ] as const;

export type Metric = typeof metrics[number];
export type Score = {
    [key in Metric]: number;
}

export const addScore = (score1: Score, question: Question, answer: AnswerType): Score => {
    return metrics.reduce((acc, metric) => {
        acc[metric] = score1[metric] + (question?.[metric]?.[answer] ?? 0);
        return acc;
    }, {} as Score);
}

export const subScore = (score1: Score, score2: Score): Score => {
    return metrics.reduce((acc, metric) => {
        acc[metric] = score1[metric] - score2[metric];
        return acc;
    }, {} as Score);
}

export const divScore = (score: Score, n: number): Score => {
    return metrics.reduce((acc, metric) => {
        acc[metric] = score[metric] / n;
        return acc;
    }, {} as Score);
}

export const distScore = (score1: Score, score2: Score): number => {
    return metrics.reduce((acc, metric) => {
        return acc + Math.abs(score1[metric] - score2[metric]);
    }, 0);
}

export type CatBreed = {
    Breed: string;
} & Score;

export const answers = ['yes', 'maybe', 'no'] as const;

export type AnswerType = typeof answers[number];

export type QuestionData = {
    [key in Metric]?: {
        [ans in AnswerType]: number;
    }
}

export const formatAnswer = (answer: AnswerType) => {
    switch (answer) {
        case 'yes':
            return 'はい';
        case 'maybe':
            return 'まあまあ';
        case 'no':
            return 'いいえ';
    }
}

export type Question = {
    question: string;
} & QuestionData;